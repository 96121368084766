/*div.vertical{
    -webkit-transform: rotate(-90deg); 
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);

    -ms-transform-origin: left top 0;
    -moz-transform-origin: left top 0;
    -webkit-transform-origin: left top 10;
    transform-origin: left top 0;

    margin-left: 30px;
    padding: 0;
    float: left;
    height:180px;
    width:5px;
    white-space: nowrap;
}*/

p.vertical{
    -webkit-transform: rotate(-90deg); 
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);

    -ms-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -webkit-transform-origin: bottom ;
    transform-origin: bottom ;

    margin-left: 30px;
    padding: 0;
    float: right;
    height:40px;
    width:10px;
    white-space: nowrap;
  } 