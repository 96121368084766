@import "globals";

div.page {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

section > div:not(.about-menu, .w404),
section > section {
  margin: 1em;
}

section.home,
section.classification,
section.newchars,
section.stat,
section.directories,
section.about,
section.explorer,
section.admin,
section.psychologist,
section.login,
section.registration,
section.testing,
section.contact {
  flex-grow: 1;
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 3em;
    color: slategray;
  }

}

div.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  h1 {
    font-size: 3em;
    color: slategray;
  }

  > nav {
    background-color: slategray;
    padding: 1em;
    border-top: dashed .5em ghostwhite;
    border-bottom: dashed .5em ghostwhite;
    width: calc(100% - 2em);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    a {
      font-size: 2em;
      color: ghostwhite;
      flex-basis: 200px;
    }
  }

}

section.about {
  >section {
    padding: 2em;
  }
}

/*section.home {
  >section {
    padding: 2em;
  }
}*/

div.w404 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  justify-content: center;
  align-items: center;
  background-color: darkred;
  color: ghostwhite;
  font-size: 1.5em;

  a {
    color: white;
    text-decoration: underline;
    margin: 1em;
    &:hover {
      color: yellow;
      text-decoration: none;
    }
  }
}