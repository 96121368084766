@import "globals";

nav.main-menu {

  display: flex;
  flex-direction: column;
  background-color: slategray;
  color: ghostwhite;
  height: 100%;

  width: 20%;
  min-width: 20%;

  a {
    color: ghostwhite;
    text-align: center;
    padding: 1em;
    font-size: 1.5em;
  }

}

div.about-menu {
  width: 100%;
  list-style-type: none;
  display: flex;
  background-color: slategray;

  > li {

    flex-grow: 1;

    > a {
      display: block;
      width: calc(100% - 1em);
      text-align: center;
      text-decoration: none;
      color: ghostwhite;
      padding: .5em;
    }

  }
}